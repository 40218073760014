import { getCookie, areSetsEqual } from "./utils";

export class Parameters {
  constructor(activeParameters) {
    this.activeParametersDict = activeParameters;
    this.initialActiveParametersDict = JSON.parse(
      JSON.stringify(activeParameters)
    );
  }

  async requestURL(max_retries = 5) {
    try {
      const response = await fetch("/ajax/url/", {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
          "X-CSRFToken": getCookie("csrftoken"),
        },
        body: JSON.stringify(this.activeParametersDict),
      });
      const responseData = await response.json();
      return responseData;
    } catch (error) {
      if (max_retries === 1) throw error;
      return await this.requestURL(max_retries - 1);
    }
  }

  reloadPage() {
    /* Lädt die URL neu */
    this.requestURL().then((response) => {
      history.pushState({}, "", response.url);
      window.location.href = response["url"];
    });
  }

  checkIfChanged(parameterClass) {
    /* Überprüft, ob sich der Wert des Parameters geändert hat. */
    const parameterSet = this.getParameterSet(
      parameterClass,
      this.activeParametersDict
    );
    const initialParameterSet = this.getParameterSet(
      parameterClass,
      this.initialActiveParametersDict
    );

    return !areSetsEqual(parameterSet, initialParameterSet);
  }

  checkIfChangedAny() {
    /* Überprüft, ob sich irgendein Wert geändert hat. */
    let changed = false;

    // Alle Parameter-Klassen durchgehen
    Object.keys(this.activeParametersDict["parameters"]).forEach((key) => {
      if (this.checkIfChanged(key)) {
        changed = true;
      }
    });

    // Parameter-Klassen, die evtl. entfernt wurden
    Object.keys(this.initialActiveParametersDict["parameters"]).forEach(
      (key) => {
        if (
          !Object.keys(this.activeParametersDict["parameters"]).includes(key)
        ) {
          changed = true;
        }
      }
    );

    return changed;
  }

  activeFilterCount(parameterClass = "") {
    let count = 0;
    const excludeList = ["sort"];

    if (parameterClass === "") {
      Object.keys(this.activeParametersDict["parameters"]).forEach((key) => {
        if (!excludeList.includes(key)) {
          count++;
        }
      });
    } else {
      try {
        count = Object.keys(
          this.activeParametersDict["parameters"][parameterClass]
        ).length;
      } catch (error) {
        count = 0;
      }
    }

    return count;
  }

  removeParameter(parameterClass, parameterKey = "", reloadOnChange = false) {
    /* Entfernt den Parameter-Key aus dem active_parameters_dict, sofern der
    Key vorhanden ist. Emitiert ein Event mit den Details, sofern 
    reloadOnChange = false ist. */
    const deletedParameterKeys = [];

    try {
      if (parameterKey !== "") {
        deletedParameterKeys.push(parameterKey);
        delete this.activeParametersDict["parameters"][parameterClass][
          parameterKey
        ];
        if (
          Object.keys(this.activeParametersDict["parameters"][parameterClass])
            .length === 0
        ) {
          delete this.activeParametersDict["parameters"][parameterClass];
        }
      } else {
        deletedParameterKeys.push(
          ...Object.keys(
            this.activeParametersDict["parameters"][parameterClass]
          )
        );
        delete this.activeParametersDict["parameters"][parameterClass];
      }
    } catch (error) {}

    // Wenn reloadOnChange == true, wird die URL neu geladen.
    const parameterChanged = this.checkIfChanged(parameterClass);
    if (parameterChanged && reloadOnChange) {
      this.reloadPage();
    }

    // Event: Parameter-Remove mit parameterClass und parameterKey
    if (!reloadOnChange) {
      deletedParameterKeys.forEach((parameterKey) => {
        document.dispatchEvent(
          new CustomEvent("parameter-remove", {
            detail: {
              parameterClass: parameterClass,
              parameterKey: parameterKey,
              initParameterChanged: parameterChanged,
              activeParameterCount: this.activeFilterCount(parameterClass),
            },
          })
        );
      });
    }
  }

  removeAllParameters(exemptArray = []) {
    /* Entfernt alle Parameter-Keys, sofern sie nicht im Array exempt enthalten
    sind */
    Object.keys(this.activeParametersDict["parameters"]).forEach((key) => {
      if (!exemptArray.includes(key)) {
        delete this.activeParametersDict["parameters"][key];
      }
    });

    this.reloadPage();
  }

  updateParameter(
    parameterClass,
    parameterKey,
    parameterValue = "",
    reloadOnChange = false
  ) {
    /* Checkt ob der Parameter-Key bereits im active_parameters_dict-
		Objekt vorhanden ist und updatet ggf. den Paramter-Value. Emitiert ein
    Event mit den Details des Updates, falls reloadOnChange == false. */

    if (parameterClass in this.activeParametersDict["parameters"]) {
      if (
        parameterKey in this.activeParametersDict["parameters"][parameterClass]
      ) {
        if (parameterValue !== "") {
          if (
            this.activeParametersDict["parameters"][parameterClass][
              parameterKey
            ] !== parameterValue
          ) {
            this.activeParametersDict["parameters"][parameterClass][
              parameterKey
            ] = parameterValue;
          }
        }
      } else {
        this.activeParametersDict["parameters"][parameterClass][parameterKey] =
          parameterValue;
      }
    } else {
      this.activeParametersDict["parameters"][parameterClass] = {};
      this.activeParametersDict["parameters"][parameterClass][parameterKey] =
        parameterValue;
    }

    // Wenn reloadOnChange == true, wird die URL neu geladen.
    const parameterChanged = this.checkIfChanged(parameterClass);
    if (parameterChanged && reloadOnChange) {
      this.reloadPage();
    }

    // Event: Parameter-Update mit parameterClass und parameterKey und ggf. parameterValue
    document.dispatchEvent(
      new CustomEvent("parameter-update", {
        detail: {
          parameterClass: parameterClass,
          parameterKey: parameterKey,
          parameterValue: parameterValue,
          initParameterChanged: parameterChanged,
          activeParameterCount: this.activeFilterCount(parameterClass),
        },
      })
    );
  }

  getParameterSet(parameterClass, parametersDict) {
    /* Gibt ein Array mit allen Parameter-Keys zurück, die im parametersDict
    vorhanden sind. */
    let parameterSet = [];

    if (parameterClass in parametersDict["parameters"]) {
      for (const [key, value] of Object.entries(
        parametersDict["parameters"][parameterClass]
      )) {
        let concat_key_value = key + ":" + value;
        parameterSet.push(concat_key_value);
      }
    }

    return new Set(parameterSet);
  }

  static init() {
    if (typeof activeParameters !== "undefined") {
      const parameters = new Parameters(activeParameters);
      window.parameters = parameters;
    }
  }
}
Parameters.init();
