export class MobileFilterSubMenuComponent extends HTMLElement {
  constructor() {
    super();
  }

  connectedCallback() {
    // Attribute
    this.deleteButton = this.querySelector(
      ".mobile_filter__filter_headline_action_text--delete"
    );
    this.backButton = this.querySelector(".mobile_filter__filter_action--back");
    this.saveButton = this.querySelector(".mobile_filter__filter_action--save");

    // Delete-Button: click
    this.deleteButton.addEventListener("click", () => {
      this.resetFilters();
    });

    // Back-Button: click
    this.backButton.addEventListener("click", () => {
      this.closeMenu();
    });

    // Save-Button: click
    this.saveButton.addEventListener("click", () => {
      this.saveCloseMenu();
    });

    // Links: Prevent Default
    this.querySelectorAll("a").forEach((link) => {
      link.addEventListener("click", this.preventDefault);
    });
  }

  preventDefault(evt) {
    evt.preventDefault();
  }

  closeMenu() {
    this.setAttribute("submenu-active", "false");
  }

  saveCloseMenu() {
    this.setAttribute("submenu-active", "false");
    this.dispatchEvent(
      new Event("mobileFilterSubMenu:save", { bubbles: true })
    );
  }

  filtersModified() {
    this.backButton.classList.remove("mobile_filter__filter_action--active");
    this.saveButton.classList.add("mobile_filter__filter_action--active");
  }

  filtersNotModified() {
    this.backButton.classList.add("mobile_filter__filter_action--active");
    this.saveButton.classList.remove("mobile_filter__filter_action--active");
  }
}
