import "../../../css/src/modules/nav-assistant-component.scss";

const debounce = (func, limit) => {
  let lastFunc;
  return function (...args) {
    const context = this;
    clearTimeout(lastFunc);
    lastFunc = setTimeout(() => {
      func.apply(context, args);
    }, limit); //no calc here, just use limit
  };
};

export class NavAssistantComponent extends HTMLElement {
  constructor() {
    super();
  }

  connectedCallback() {
    this.hScrollContainer = this.querySelector(".hscroll__container");
    this.cursorLeft = this.querySelector(".hscroll__cursor--left");
    this.cursorRight = this.querySelector(".hscroll__cursor--right");
    this.linkItems = this.querySelectorAll(".nav-assistant__link");

    if (this.cursorLeft && this.cursorRight) {
      // Initialer Status
      this.updateCursorStatus();

      // Resize-Observer
      this.resizeObserver = new ResizeObserver(
        this.updateCursorStatus.bind(this)
      );
      this.resizeObserver.observe(this.hScrollContainer);

      // Scroll-Event-Handler
      this.hScrollContainer.addEventListener(
        "scroll",
        debounce(this.updateCursorStatus.bind(this), 100)
      );

      // Click Handler
      this.cursorLeft.addEventListener(
        "click",
        this.handleCursorClick.bind(this)
      );
      this.cursorRight.addEventListener(
        "click",
        this.handleCursorClick.bind(this)
      );
      if (this.getAttribute("handle-click") === "true") {
        this.linkItems.forEach((linkItem) => {
          linkItem.addEventListener("click", this.handleLinkClick.bind(this));
        });
      }
    }
  }

  getScrollPosition(listElement) {
    var xscrollLeft = 0;
    var xscrollLeftMax = 0;
    var xscrollWidth = 0;
    var xclientWidth = 0;

    xscrollLeft = listElement.scrollLeft;
    xscrollWidth = listElement.scrollWidth;
    xclientWidth = listElement.clientWidth;
    xscrollLeftMax = xscrollWidth - xclientWidth;

    return {
      scrollLeft: xscrollLeft,
      scrollLeftMax: xscrollLeftMax,
      scrollWidth: xscrollWidth,
      clientWidth: xclientWidth,
    };
  }

  updateCursorStatus() {
    var scrollPositions = this.getScrollPosition(this.hScrollContainer);

    if (scrollPositions["scrollLeftMax"] > scrollPositions["scrollLeft"]) {
      this.cursorRight.classList.add("hscroll__cursor--visible");
    } else {
      this.cursorRight.classList.remove("hscroll__cursor--visible");
    }

    if (scrollPositions["scrollLeft"] > 0) {
      this.cursorLeft.classList.add("hscroll__cursor--visible");
    } else {
      this.cursorLeft.classList.remove("hscroll__cursor--visible");
    }
  }

  handleCursorClick(event) {
    var scrollPositions = this.getScrollPosition(this.hScrollContainer);
    var scrollLeft = scrollPositions["scrollLeft"];
    var scrollLeftMax = scrollPositions["scrollLeftMax"];
    var clientWidth = scrollPositions["clientWidth"] / 2;

    if (event.target === this.cursorRight) {
      var scrollOffset = Math.min(scrollLeft + clientWidth, scrollLeftMax);
      if (scrollLeftMax - scrollOffset < 100) {
        scrollOffset = scrollLeftMax;
      }
      if (scrollOffset)
        this.hScrollContainer.scroll({
          left: scrollOffset,
          behavior: "smooth",
        });
    } else if (event.target === this.cursorLeft) {
      var scrollOffset = Math.max(scrollLeft - clientWidth, 0);
      if (scrollOffset < 100) {
        scrollOffset = 0;
      }
      this.hScrollContainer.scroll({
        left: scrollOffset,
        behavior: "smooth",
      });
    }

    this.updateCursorStatus();
  }

  handleLinkClick(event) {
    event.preventDefault();
    const linkItem = event.target.closest(".nav-assistant__link");
    if ("optionActive" in linkItem.dataset) {
      if (linkItem.dataset.optionActive === "true") {
        linkItem.dataset.optionActive = "false";
        linkItem.classList.remove("nav-assistant__link--active");
        window.parameters.removeParameter(
          this.dataset.filterSlug,
          linkItem.dataset.optionSlug,
          true
        );
      } else {
        linkItem.dataset.optionActive = "true";
        linkItem.classList.add("nav-assistant__link--active");
        window.parameters.updateParameter(
          this.dataset.filterSlug,
          linkItem.dataset.optionSlug,
          "",
          true
        );
      }
    } else {
      const linkUrl = linkItem.getAttribute("href") || linkItem.dataset.url;
      window.location.href = linkUrl;
    }
  }
}
window.customElements.define("nav-assistant", NavAssistantComponent);
