import { Parameters } from "./modules/parameters";
import { MobileMenuComponent } from "./modules/mobile-menu-component";
import {
  ColorFilterComponent,
  CategoricalFilterComponent,
} from "./modules/filter-components";
import { ContentContainerComponent } from "./modules/content-container-component";
import { FilterContainer } from "./modules/filter-container-component";
import { HeaderComponent } from "./modules/header-component";
import { HeroSearchComponent } from "./modules/hero-search-component";
import { LinkListComponent } from "./modules/linklist-component";
import { MobileFilterComponent } from "./modules/mobile-filter-component";
import { MobileHeaderComponent } from "./modules/mobile-header-component";
import { NavAssistantComponent } from "./modules/nav-assistant-component";
import { PriceFilterComponent } from "./modules/filter-price-component";
import { ProductGroupComponent } from "./modules/product-group-component";
import { ProductGroupDetailComponent } from "./modules/product-group-detail-component";
import { SizeFilterComponent } from "./modules/size-filter-component";
import { SortingComponent } from "./modules/sorting-component";
import { WishListHeaderComponent } from "./modules/wishlist-header-component";
import { RecommendationsComponent } from "./modules/recommendations-component";
import { CouponCardComponent } from "./modules/coupon-card-component";
import { CouponModalComponent } from "./modules/coupon-modal-component";
import { CouponsComponent } from "./modules/coupons-component";
import { QuickLinkComponent } from "./modules/quick-link-component";

import "../../css/src/bootstrap_cleaned.min.css";
import "../../css/src/modules/wishlist-component.scss";
import "../../css/src/style.scss";

// import Sentry SDK
import(
  /* webpackChunkName: "sentry" */
  /* webpackMode: "lazy" */
  /* webpackPrefetch: true */
  "./modules/sentry"
);
