import noUiSlider from "nouislider";
import "nouislider/distribute/nouislider.css";

import { FilterComponent } from "./filter-components";
import "../../../css/src/modules/filter-price-component.scss";

export class PriceFilterComponent extends FilterComponent {
  connectedCallback() {
    super.connectedCallback();

    // Check: Filter-Container initialisiert?
    if (this.parentElement.getAttribute("init-filters") === "true") {
      return;
    }

    // Attribute
    this.slugsSet = new Set();
    this.inputPriceFrom = this.querySelectorAll(".filter__price_input_text")[0];
    this.slugsSet.add(this.inputPriceFrom.dataset.filterSlug);
    this.inputPriceTo = this.querySelectorAll(".filter__price_input_text")[1];
    this.slugsSet.add(this.inputPriceTo.dataset.filterSlug);
    this.checkBoxSale = this.querySelector(".filter__filter_checkbox");
    this.slugsSet.add(this.checkBoxSale.dataset.filterSlug);
    this.priceMin = Number(this.getAttribute("price-min"));
    this.priceMax = Number(this.getAttribute("price-max"));
    this.initPriceFrom = Number(this.getAttribute("price-from"));
    this.initPriceTo = Number(this.getAttribute("price-to"));
    this.initSaleCheckBox = this.checkBoxSale.getAttribute("checked");

    // Update active filter count
    this.updateActiveFilterCount(this.getActiveFilterCount());

    // Price-Inputs: Change-Event
    [this.inputPriceFrom, this.inputPriceTo].forEach((elt) => {
      elt.addEventListener("input", () => {
        this.updatePriceSlider();
      });
    });

    // Sale-Checkbox: Click-Event
    this.checkBoxSale.addEventListener("click", () => {
      if (this.checkBoxSale.getAttribute("checked") === "true") {
        parameters.removeParameter(
          this.checkBoxSale.dataset.filterSlug,
          this.checkBoxSale.dataset.filterSlug
        );
      } else if (this.checkBoxSale.getAttribute("available") === "True") {
        parameters.updateParameter(
          this.checkBoxSale.dataset.filterSlug,
          this.checkBoxSale.dataset.filterSlug
        );
      }
    });

    // Reset-Button: Click-Event
    this.filterButtonReset = this.querySelector(".filter__content-btn--reset");
    this.filterButtonReset.addEventListener("click", () => {
      if (
        this.filterButtonReset.classList.contains(
          "filter__content-btn--visible"
        )
      ) {
        this.resetFilters();
        this.slugsSet.forEach((slug) => {
          parameters.removeParameter(slug, "", false);
        });
        this.removeAttribute("open");
        this.updateActiveFilterCount(0);
        parameters.reloadPage();
      }
    });

    // noUiSlider
    if (this.priceMin !== this.priceMax) {
      noUiSlider.cssClasses.target += " filter__price_slider";
      this.priceSlider = noUiSlider.create(
        this.querySelector(".filter__price_slider"),
        {
          start: [this.initPriceFrom, this.initPriceTo],
          step: 1,
          connect: true,
          range: {
            min: this.priceMin,
            max: this.priceMax,
          },
          cssPrefix: "filter__price_slider__",
        }
      );

      // noUiSlider: update-Event
      this.priceSlider.on("update", (values, handle) => {
        this.updatePriceInput(values, handle);
      });

      // noUiSlider: change-Event
      this.priceSlider.on("change", (values, handle) => {
        if (handle === 0) {
          parameters.updateParameter(
            this.inputPriceFrom.dataset.filterSlug,
            "active_from",
            Math.trunc(values[handle])
          );
        } else {
          parameters.updateParameter(
            this.inputPriceTo.dataset.filterSlug,
            "active_to",
            Math.trunc(values[handle])
          );
        }
      });

      // noUiSlider: set-Event
      this.priceSlider.on("set", (values, handle) => {
        if (handle === 0) {
          parameters.updateParameter(
            this.inputPriceFrom.dataset.filterSlug,
            "active_from",
            Math.trunc(values[handle])
          );
        } else {
          parameters.updateParameter(
            this.inputPriceTo.dataset.filterSlug,
            "active_to",
            Math.trunc(values[handle])
          );
        }
      });
    }

    // Event-Handler for Parameter-Updates
    document.addEventListener("parameter-update", (event) => {
      if (this.slugsSet.has(event.detail.parameterClass)) {
        this.handleParameterUpdate(event.detail);
      }
    });

    // Event-Handler for Parameter-Removals
    document.addEventListener("parameter-remove", (event) => {
      if (this.slugsSet.has(event.detail.parameterClass)) {
        this.handleParameterRemove(event.detail);
      }
    });
  }

  handleClose() {
    // Remove Event-Handler outside click
    document.removeEventListener("click", this.outsideClickHandler.bind(this));

    // Remove alignment
    this.filterContentContainer.classList.remove(
      "filter__content-container--align-right"
    );

    // Compare both sets for equality
    let changed = false;
    this.slugsSet.forEach((slug) => {
      if (parameters.checkIfChanged(slug)) {
        changed = true;
      }
    });
    if (changed) {
      parameters.reloadPage();
    }
  }

  handleParameterUpdate(parameter) {
    if (parameter.parameterClass === this.checkBoxSale.dataset.filterSlug) {
      this.checkBoxSale.setAttribute("checked", "true");
    } else if (
      parameter.parameterClass === this.inputPriceFrom.dataset.filterSlug &&
      parameter.parameterKey === this.inputPriceFrom.dataset.optionSlug
    ) {
      if (
        this.initPriceFrom.value != parameter.parameterValue &&
        !this.blockPriceInputs
      ) {
        this.inputPriceFrom.value = parameter.parameterValue;
      }
    } else if (
      parameter.parameterClass === this.inputPriceTo.dataset.filterSlug &&
      parameter.parameterKey === this.inputPriceTo.dataset.optionSlug
    ) {
      if (
        this.initPriceTo.value != parameter.parameterValue &&
        !this.blockPriceInputs
      ) {
        this.inputPriceTo.value = parameter.parameterValue;
      }
    }
    this.updateActiveFilterCount(this.getActiveFilterCount());
    this.checkButtonVisibility();
  }

  handleParameterRemove(parameter) {
    if (parameter.parameterClass === this.checkBoxSale.dataset.filterSlug) {
      this.checkBoxSale.setAttribute("checked", "false");
    } else if (
      parameter.parameterClass === this.inputPriceFrom.dataset.filterSlug &&
      parameter.parameterKey === this.inputPriceFrom.dataset.optionSlug
    ) {
      this.inputPriceFrom.value = this.initPriceFrom.value;
    } else if (
      parameter.parameterClass === this.inputPriceTo.dataset.filterSlug &&
      parameter.parameterKey === this.inputPriceTo.dataset.optionSlug
    ) {
      this.inputPriceTo.value = this.initPriceTo.value;
    }
    this.updateActiveFilterCount(this.getActiveFilterCount());
    this.checkButtonVisibility();
  }

  getActiveFilterCount() {
    let activeFilterCount = 0;
    this.slugsSet.forEach((slug) => {
      activeFilterCount += parameters.activeFilterCount(slug);
    });
    return activeFilterCount;
  }

  checkButtonVisibility() {
    // Save-Button
    let changed = false;
    this.slugsSet.forEach((slug) => {
      if (parameters.checkIfChanged(slug)) {
        changed = true;
      }
    });
    if (changed) {
      this.filterButtonSave.classList.add("filter__content-btn--visible");
    } else {
      this.filterButtonSave.classList.remove("filter__content-btn--visible");
    }

    if (this.dataset.activeFilterCount > 0) {
      this.filterButtonReset.classList.add("filter__content-btn--visible");
    } else {
      this.filterButtonReset.classList.remove("filter__content-btn--visible");
    }
  }

  resetFilters() {
    // Price Slider -> Price Inputs
    if (this.hasOwnProperty("priceSlider")) {
      this.priceSlider.set([this.priceMin, this.priceMax]);
    }

    // Sale Boolean
    this.checkBoxSale.setAttribute("checked", "false");

    this.setAttribute("has-active-filters", "false");
  }

  updatePriceSlider() {
    this.blockPriceInputs = true;
    this.priceSlider.set([this.inputPriceFrom.value, this.inputPriceTo.value]);
    this.blockPriceInputs = false;
  }

  updatePriceInput(values, handle) {
    if (this.blockPriceInputs) {
      return;
    }
    if (handle === 0) {
      this.inputPriceFrom.value = Math.trunc(values[0]);
    } else {
      this.inputPriceTo.value = Math.trunc(values[1]);
    }
  }
}
window.customElements.define("price-filter", PriceFilterComponent);
