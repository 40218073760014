import { MobileFilterSubMenuComponent } from "./mobile-filter-submenu-component";
import { splitSlugName } from "./utils";
import "../../../css/src/modules/mobile-categorical-filter-component.scss";

export class MobileCategoricalFilterComponent extends MobileFilterSubMenuComponent {
  constructor() {
    super();
  }

  connectedCallback() {
    super.connectedCallback();

    // Attribute
    this.filterSlug = this.getAttribute("filter-slug");
    this.filterOptionContainer = this.querySelector("#filter-option-container");
    this.filterOptionTemplate = this.querySelector(
      "#mobile-filter-filter-option-template"
    );
    this.searchOptionContainer = this.querySelector("#search-option-container");
    this.initActiveItems = this.activeItemsSet;

    // Suche
    if (this.getAttribute("has-search-bar") === "true") {
      this.searchBar = this.querySelector(
        ".mobile_filter__filter_items_search"
      );
      this.searchBarInput = this.querySelector(
        ".mobile_filter__filter_items_search_input"
      );
      this.searchBarDeleteBtn = this.querySelector(
        ".mobile_filter__filter_container_icon--search-delete"
      );
      this.searchValuePlaceHolder = this.searchBarInput.value;

      // Suche: focus-Event
      this.searchBarInput.addEventListener("focus", () => {
        this.searchBarInput.value = "";
        this.searchBar.setAttribute("has-focus", "true");
      });

      // Suche: input-Event
      this.searchBarInput.addEventListener("input", (evt) => {
        if (this.searchBarInput.value.length > 1) {
          // Input-Wert vorhanden + min. 2 Zeichen lang
          this.hideFilterOptionContainer();
          this.executeSearch();
        } else {
          if (
            evt.inputType === "deleteContentBackward" &&
            this.searchBarInput.value.length === 0
          ) {
            // Input-Wert gelöscht
            this.showFilterOptionContainer();
          }
        }
      });

      // Suche Löschen-Button: click-Event
      this.searchBarDeleteBtn.addEventListener("click", () => {
        this.clearSearchBar();
        this.searchBar.setAttribute("has-focus", "false");
      });
    }

    // Aktive Filter?
    this.checkActiveFilters();

    // Filter-Options-Container: click-Event
    this.filterOptionContainer.addEventListener("click", (evt) => {
      this.updateFilter(evt.target);
    });

    // Search-Options-Container: click-Event
    this.searchOptionContainer.addEventListener("click", (evt) => {
      this.toggleSearchFilterOption(evt.target);
    });

    // Event-Handler for Parameter-Updates
    document.addEventListener("parameter-update", (event) => {
      if (event.detail.parameterClass === this.filterSlug) {
        this.handleParameterUpdate(event.detail);
      }
    });

    // Event-Handler for Parameter-Removals
    document.addEventListener("parameter-remove", (event) => {
      if (event.detail.parameterClass === this.filterSlug) {
        this.handleParameterRemove(event.detail);
      }
    });
  }

  get activeItemsSet() {
    const activeItems = new Set();
    this.querySelectorAll(
      ".mobile_filter__filter_option[data-active='true']"
    ).forEach((elt) => {
      activeItems.add(elt.dataset.slug);
    });
    return activeItems;
  }

  clearSearchBar() {
    this.searchBarInput.value = this.searchValuePlaceHolder;
    this.showFilterOptionContainer();
  }

  checkActiveFilters() {
    if (parameters.activeFilterCount(this.filterSlug) > 0) {
      this.setAttribute("has-active-filters", "true");
    } else {
      this.setAttribute("has-active-filters", "false");
    }
  }

  checkModified() {
    if (parameters.checkIfChanged(this.filterSlug)) {
      this.filtersModified();
    } else {
      this.filtersNotModified();
    }
  }

  handleParameterUpdate(detail) {
    const itemSelector = `.mobile_filter__filter_option[data-slug='${detail.parameterKey}']`;
    const clickedOption = this.querySelector(itemSelector);
    clickedOption.dataset.active = "true";
    this.checkModified();
  }

  handleParameterRemove(detail) {
    if (detail["parameterKey"] !== "") {
      const itemSelector = `.mobile_filter__filter_option[data-slug='${detail.parameterKey}']`;
      const clickedOption = this.querySelector(itemSelector);
      clickedOption.dataset.active = "false";
    } else {
      this.querySelectorAll(
        ".mobile_filter__filter_option[data-active='true']"
      ).forEach((option) => {
        option.dataset.active = "false";
      });
    }
    this.checkModified();
  }

  executeSearch() {
    const encInputValue = encodeURIComponent(this.searchBarInput.value);
    const parameterUri = parameters.getURL(
      "absolute",
      [`ajax_search=${encInputValue}`],
      [this.filterSlug]
    );
    const requestUri = `/ajax/search/${this.filterSlug}${parameterUri}`;
    fetch(requestUri)
      .then((response) => response.json())
      .then((resp_json) => this.addSearchOptions(resp_json));
  }

  addSearchOptions(resp_json) {
    const currentActiveItems = this.activeItemsSet;
    const tempFragment = document.createDocumentFragment();
    const filterOption = this.filterOptionTemplate.content.querySelector(
      ".mobile_filter__filter_option"
    );
    const filterOptionText = this.filterOptionTemplate.content.querySelector(
      ".mobile_filter__filter_option_text"
    );
    resp_json["search_results"].forEach((elt) => {
      const optionSlug = splitSlugName(elt.result, "slug");
      const optionName = splitSlugName(elt.result, "name");
      filterOption.dataset.slug = optionSlug;
      filterOption.dataset.name = optionName;
      if (currentActiveItems.has(optionSlug)) {
        filterOption.dataset.active = "true";
      } else {
        filterOption.dataset.active = "false";
      }
      filterOptionText.textContent = optionName;
      let filterOptionClone = filterOption.cloneNode(true);
      tempFragment.appendChild(filterOptionClone);
    });
    window.requestAnimationFrame(() => {
      this.searchOptionContainer.innerHTML = "";
      this.searchOptionContainer.appendChild(tempFragment);
    });
  }

  updateFilter(target) {
    const targetOption = target.closest(".mobile_filter__filter_option");
    if (targetOption.dataset.active === "true") {
      parameters.removeParameter(this.filterSlug, targetOption.dataset.slug);
    } else {
      parameters.updateParameter(this.filterSlug, targetOption.dataset.slug);
    }
    this.checkModified();
    this.checkActiveFilters();
  }

  showFilterOptionContainer() {
    this.searchOptionContainer.classList.add(
      "mobile_filter__filter_option_container--hide"
    );
    this.filterOptionContainer.classList.remove(
      "mobile_filter__filter_option_container--hide"
    );
  }

  hideFilterOptionContainer() {
    this.searchOptionContainer.classList.remove(
      "mobile_filter__filter_option_container--hide"
    );
    this.filterOptionContainer.classList.add(
      "mobile_filter__filter_option_container--hide"
    );
  }

  resetFilters() {
    this.querySelectorAll(
      ".mobile_filter__filter_option[data-active='true']"
    ).forEach((elt) => {
      elt.dataset.active = "false";
      parameters.removeParameter(this.filterSlug, elt.dataset.slug);
    });
    this.setAttribute("has-active-filters", "false");
    this.checkModified();
  }

  toggleSearchFilterOption(evt_target) {
    const searchOption = evt_target.closest(".mobile_filter__filter_option");
    const searchOptionName = searchOption.dataset.name;
    if (searchOption.dataset.active === "true") {
      // searchOption bisher aktiv
      searchOption.dataset.active = "false";
      const filterOption = this.filterOptionContainer.querySelector(
        `.mobile_filter__filter_option[data-slug=${searchOption.dataset.slug}]`
      );
      if (filterOption) {
        filterOption.dataset.active = "false";
      }
    } else {
      // searchOption bisher inaktiv
      searchOption.dataset.active = "true";
      const filterOptionTwin = this.filterOptionContainer.querySelector(
        `.mobile_filter__filter_option[data-name="${searchOptionName}"]`
      );
      if (filterOptionTwin) {
        // FilterOption vorhanden: Aktiv setzen + Return
        filterOptionTwin.dataset.active = "true";
        this.checkModified();
        this.checkActiveFilters();
        return;
      }

      const currentFilterOptions = this.filterOptionContainer.querySelectorAll(
        ".mobile_filter__filter_option"
      );
      const searchOptionClone = searchOption.cloneNode(true);
      if (currentFilterOptions.length > 0) {
        // FilterOptions vorhanden
        var inserted = false;
        currentFilterOptions.forEach((elt) => {
          if (inserted) {
            return;
          }
          let compResult = searchOptionName.localeCompare(elt.dataset.name);
          if (compResult <= 0) {
            // searchOption davor einfügen
            this.filterOptionContainer.insertBefore(searchOptionClone, elt);
            inserted = true;
          }
        });
      } else {
        // FilterOptions nicht vorhanden
        this.filterOptionContainer.appendChild(searchOptionClone);
      }
    }
    this.checkModified();
    this.checkActiveFilters();
  }
}
window.customElements.define(
  "mobile-categorical-filter",
  MobileCategoricalFilterComponent
);
