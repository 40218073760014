import "../../../css/src/modules/sorting-component.scss";
import { Parameters } from "./parameters";

export class SortingComponent extends HTMLElement {
  constructor() {
    super();
  }

  connectedCallback() {
    this.sortingTitle = this.querySelector(".sorting__title");

    // Add event listener to the sorting title
    this.sortingTitle.addEventListener("click", this.toggleSorting.bind(this));

    // Add event listener to the sorting items
    this.sortingItems = this.querySelectorAll(".sorting__item");
    this.sortingItems.forEach((sortingItem) => {
      sortingItem.addEventListener("click", this.handleSorting.bind(this));
    });
  }

  outsideClickHandler(event) {
    if (!this.contains(event.target) && this.hasAttribute("open")) {
      this.removeAttribute("open");
    }
  }

  toggleSorting() {
    window.requestAnimationFrame(() => {
      if(this.hasAttribute("open")) {
        this.removeAttribute("open");
        document.removeEventListener("click", this.outsideClickHandler.bind(this));
      } else {
        this.setAttribute("open", "");
        document.addEventListener("click", this.outsideClickHandler.bind(this));
      }
    });
  }

  handleSorting(event) {
    let clickedOptionSlug = event.target.dataset.slug;
    parameters.updateParameter("sort", "sort", clickedOptionSlug, true);
  }
}
window.customElements.define("sorting-dropdown", SortingComponent);
