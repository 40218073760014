import { getCookie } from "./utils";

const ioOptions = {
  root: null,
  rootMargin: "0px",
  threshold: 0.75,
};

export class CouponsComponent extends HTMLElement {
  constructor() {
    super();

    this.couponCards = null;
    this.io = null;
  }

  connectedCallback() {
    this.couponCards = this.querySelectorAll("coupon-card-component");

    // create an observer instance
    this.io = new IntersectionObserver(
      this.handleIntersect.bind(this),
      ioOptions
    );

    // start observing coupon cards
    this.couponCards.forEach((couponCard) => {
      this.io.observe(couponCard);
    });
  }

  handleIntersect(entries, observer) {
    entries.forEach((entry) => {
      if (entry.isIntersecting) {
        let couponId = entry.target.dataset.cid;
        observer.unobserve(entry.target);
        this.trackImpression(couponId);
      }
    });
  }

  trackImpression(couponId) {
    fetch(`/gutscheine/tracking/impression/${couponId}/`, {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
        "X-CSRFToken": getCookie("csrftoken"),
      },
      keepalive: true,
    });
  }
}
window.customElements.define("coupons-component", CouponsComponent);
