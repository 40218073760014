import { getCookie } from "./utils";
import "../../../css/src/modules/coupon-modal-component.scss";

export class CouponModalComponent extends HTMLElement {
  constructor() {
    super();

    // Copy code button
    this.copyCodeBtn = null;
    this.copyCodeBtnText = null;
    this.copyCodeClipboardIcon = null;
    this.copyCodeCheckmarkIcon = null;

    // Feedback
    this.feedbackBtns = null;
    this.feedbackText = null;

    // Details
    this.showDetails = null;
    this.details = null;
    this.detailsOpen = false;
  }

  connectedCallback() {
    this.copyCodeBtn = this.querySelector(".coupon-modal__content__code__btn");
    this.copyCodeBtnText = this.querySelector(".coupon-modal__content__code__btn > span");
    this.copyCodeClipboardIcon = this.querySelector(".coupon-modal__content__code__btn__icon--clipboard");
    this.copyCodeCheckmarkIcon = this.querySelector(".coupon-modal__content__code__btn__icon--checkmark");

    this.feedbackBtns = this.querySelectorAll(".coupon-modal__feedback__btn");
    this.feedbackText = this.querySelector(".coupon-modal__feedback__btn__text");

    this.showDetails = this.querySelector(".show-details");
    this.details = this.querySelector(".coupon-modal__details");

    // page element: add modal-open 
    document.querySelector("#page__coupons").classList.add("modal-open");

    // Add event listener to show details button
    this.showDetails.addEventListener("click", (e) => {
      e.stopPropagation();
      this.toggleDetails();
    });

    // Add event listener to modal close button
    this.querySelector(".coupon-modal__close__icon").addEventListener("click", (e) => {
      e.stopPropagation();
      this.closeModal();
    }
    );

    // Add event listener to modal content
    this.querySelector(".coupon-modal__content").addEventListener("click", (e) => {
      e.stopPropagation();
    }
    );

    // Add event listener to modal
    this.addEventListener("click", (e) => {
      this.closeModal();
    }
    );

    // Add event listener to copy code button
    this.copyCodeBtn.addEventListener("click", (e) => {
      this.copyCode();
    }
    );

    // Add event listener to feedback buttons
    this.feedbackBtns.forEach((btn) => {
      btn.addEventListener("click", (e) => {
        this.sendFeedback(e.target.dataset.feedback);
      }
      );
    }
    );
  }

  closeModal() {
    this.classList.remove("coupon-modal--active");
    document.querySelector("#page__coupons").classList.remove("modal-open");
  }

  copyCode() {
    this.copyCodeBtn.classList.add("coupon-modal__content__code__btn--copied");
    this.copyCodeBtnText.innerHTML = "Code kopiert";
    this.copyCodeClipboardIcon.classList.remove("coupon-modal__content__code__btn__icon--visible");
    this.copyCodeCheckmarkIcon.classList.add("coupon-modal__content__code__btn__icon--visible");

    // Copy code to clipboard (check for browser support)
    let code = this.querySelector(".coupon-modal__content__code__text").innerHTML;
    if (navigator.clipboard) {
      navigator.clipboard.writeText(code);
    } else {
      // Fallback: create textarea, copy code to it, select it, copy it, remove it
      let textarea = document.createElement("textarea");
      textarea.value = code;
      document.body.appendChild(textarea);
      textarea.select();
      document.execCommand("copy");
      document.body.removeChild(textarea);
    }

    // Reset button after 5 seconds
    setTimeout(() => {
      this.copyCodeBtn.classList.remove("coupon-modal__content__code__btn--copied");
      this.copyCodeBtnText.innerHTML = "Code kopieren";
      this.copyCodeClipboardIcon.classList.add("coupon-modal__content__code__btn__icon--visible");
      this.copyCodeCheckmarkIcon.classList.remove("coupon-modal__content__code__btn__icon--visible");
    }
      , 5000);
  }

  sendFeedback(feedback) {
    // Send feedback to server
    let couponId = this.dataset.cid;
    let merchant = this.dataset.merchant;
    let data = {
      feedback: feedback,
      couponId: couponId,
      merchant: merchant
    };

    fetch("/gutscheine/feedback/", {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
        "X-CSRFToken": getCookie("csrftoken"),
      },
      body: JSON.stringify(data),
      credentials: "same-origin"
    }
    ).then((response) => {
      if (response.ok) {
        this.feedbackBtns.forEach((btn) => {
          btn.classList.remove("coupon-modal__feedback__btn--visible");
        }
        );
        this.feedbackText.classList.add("coupon-modal__feedback__btn__text--visible");
      } else {
        console.log("Error sending feedback");
      }
    }
    );
  }

  toggleDetails() {
    this.details.classList.toggle("coupon-modal__details--open");
    this.detailsOpen = !this.detailsOpen;
    if (this.detailsOpen) {
      this.showDetails
        .querySelector(".coupon-modal__carrot")
        .classList.remove("coupon-modal__carrot--down");
      this.showDetails
        .querySelector(".coupon-modal__carrot")
        .classList.add("coupon-modal__carrot--up");
    } else {
      this.showDetails
        .querySelector(".coupon-modal__carrot")
        .classList.remove("coupon-modal__carrot--up");
      this.showDetails
        .querySelector(".coupon-modal__carrot")
        .classList.add("coupon-modal__carrot--down");
    }
  }
}
window.customElements.define("coupon-modal-component", CouponModalComponent);
