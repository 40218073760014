export class WishListHeaderComponent extends HTMLElement {
  constructor() {
    super();
  }

  connectedCallback() {
    this.wishlistCount = 0;
    this.wishListIconSvg = this.querySelector(".wishlist-icon");
    this.wishListIconText = this.querySelector(".wishlist-icon__text");

    // Event-Handler: WishListInfo-Update
    document.addEventListener("wishList:infoUpdate", (event) => {
      this.wishlistCount = event.detail.wishlistCount;
      this.wishListIconText.textContent = this.wishlistCount;
      if (this.wishlistCount > 0) {
        this.wishListIconSvg.classList.add("wishlist-icon--active");
      } else {
        this.wishListIconSvg.classList.remove("wishlist-icon--active");
      }
    });

    // Event-Handler: productGroup:addToWishList
    document.addEventListener("productGroup:addToWishList", () => {
      this.increaseCount();
    });

    // Event-Handler: productGroup:removedFromWishList
    document.addEventListener("productGroup:removedFromWishList", () => {
      this.decreaseCount();
    });

    // Main WishList: Info Request
    if (this.hasAttribute("main")) {
      this.fetchInfo().then((responseData) => {
        // wishList:infoUpdate Event
        const wishListInfoUpdateEvent = new CustomEvent("wishList:infoUpdate", {
          detail: {
            wishlistCount: responseData.count,
          },
        });
        document.dispatchEvent(wishListInfoUpdateEvent);

        // ProductGroup: Active on WishList
        if (responseData.count > 0) {
          const productGroupIds = responseData.productGroupIds;
          productGroupIds.forEach((productGroupId) => {
            const productGroups = document.querySelectorAll(
              `product-group[data-pid="${productGroupId}"]`
            )
            if(productGroups) {
              productGroups.forEach((productGroup) => {
                let productLike = productGroup.querySelector(".product__like");
                productLike.classList.add("product__like--active");
              });
            }
          });
          localStorage.setItem("wishListproductGroupIds", JSON.stringify(productGroupIds));
        } else {
          localStorage.removeItem("wishListproductGroupIds");
        }
      });
    }
  }

  increaseCount() {
    this.wishListIconSvg.classList.add("wishlist-icon--active");
    this.wishlistCount++;
    this.wishListIconText.textContent = this.wishlistCount;
  }

  decreaseCount() {
    if (this.wishlistCount > 0) {
      this.wishlistCount--;
      this.wishListIconText.textContent = this.wishlistCount;
    }
    if (this.wishlistCount === 0) {
      this.wishListIconSvg.classList.remove("wishlist-icon--active");
    }
  }

  async fetchInfo(max_retries = 5) {
    try {
      const response = await fetch("/ajax/wishlist/info", {
        method: "GET",
      });
      const responseData = await response.json();
      return responseData;
    } catch (error) {
      if (max_retries === 1) throw error;
      return await this.fetchInfo(max_retries - 1);
    }
  }
}
window.customElements.define("wishlist-header", WishListHeaderComponent);
