import { getCookie } from "./utils";
import "../../../css/src/modules/mobile-menu-component.scss";

export class MobileMenuComponent extends HTMLElement {
  constructor() {
    super();
  }

  connectedCallback() {
    // Mobile Init
    if (window.screen.width < 1200 || window.screen.height < 1200) {
      if (this.getAttribute("init-menu") === "true") {
        this.initMobileMenu();
        return
      }
    } else {
      return;
    }

    // Attribute
    this.body = document.querySelector("body");
    this.pageOverlay = document.querySelector(".page-overlay");
    this.mobileMenuBtn = document.querySelector(".header__mobile-btn--menu");
    this.contentContainer = this.querySelector(
      ".mobile_menu__content_container"
    );
    this.closeBtn = this.querySelector(".mobile_menu__headline_svg--close");
    this.navBackBtn = this.querySelector(".navigation__headline_action");
    this.navHeadline = this.querySelector(".navigation__headline_text");
    this.verticalContainer = this.querySelector(".navigation__vertical");
    this.verticalSelector = this.querySelector(
      ".navigation__vertical_selector"
    );

    // mobileMenuBtn: click-Event
    this.mobileMenuBtn.addEventListener("click", (evt) => {
      this.openMenu();
    });

    // closeBtn: click-Event
    this.closeBtn.addEventListener("click", (evt) => {
      this.closeMenu();
    });

    // navBackBtn: click-Event
    this.navBackBtn.addEventListener("click", () => {
      if (
        this.navBackBtn.classList.contains(
          "navigation__headline_action--visible"
        )
      ) {
        this.resetNavigation();
      }
    });

    // pageOverlay: click-Event
    this.pageOverlay.addEventListener("click", (evt) => {
      // Menü betroffen?
      if (this.getAttribute("open") === "true") {
        this.closeMenu();
      }
    });

    // verticalSelector: click-Event
    this.verticalSelector.addEventListener("click", (evt) => {
      const closestItem = evt.target.closest(
        ".navigation__vertical_selector_item"
      );
      if (closestItem) {
        const selectedVertical = closestItem.dataset.vertical;
        if (selectedVertical !== this.getAttribute("active-vertical")) {
          this.resetNavigation();
          window.requestAnimationFrame(() => {
            this.setAttribute("active-vertical", selectedVertical);
          });
        }
      }
    });

    // categoryItems: click-Event
    this.querySelectorAll(".navigation__category_item").forEach((elt) => {
      elt.addEventListener("click", (evt) => {
        const subContainer = this.querySelector(
          `[data-subcontainer-url='${elt.dataset.url}']`
        );
        if (subContainer) {
          evt.preventDefault();
          window.requestAnimationFrame(() => {
            elt.dataset.active = true;
            subContainer.dataset.active = true;
            this.navHeadline.textContent = elt.dataset.name;
            elt.closest(
              ".navigation__category_container[data-vertical]"
            ).dataset.active = true;
            this.navBackBtn.classList.add(
              "navigation__headline_action--visible"
            );
          });
        }
      });
    });
  }

  resetNavigation() {
    window.requestAnimationFrame(() => {
      this.navBackBtn.classList.remove("navigation__headline_action--visible");
      this.navHeadline.textContent = this.navHeadline.dataset.initval;
      this.querySelectorAll("[data-active='true']").forEach((elt) => {
        elt.dataset.active = false;
      });
    });
  }

  openMenu(vertical = "", verticalCategory = "") {
    if (vertical || verticalCategory) {
      this.navBackBtn.classList.remove("navigation__headline_action--visible");
      this.navHeadline.textContent = this.navHeadline.dataset.initval;
      this.querySelectorAll("[data-active='true']").forEach((elt) => {
        elt.dataset.active = false;
      });

      if (vertical) {
        this.setAttribute("active-vertical", vertical);
      }
      if (verticalCategory) {
        this.querySelector(
          `.navigation__category_container[data-vertical='${vertical}']`
        ).dataset.active = true;
        this.querySelector(
          `.navigation__category_item[data-url='${verticalCategory}']`
        ).dataset.active = true;
        this.querySelector(
          `[data-subcontainer-url='${verticalCategory}']`
        ).dataset.active = true;
        this.navHeadline.textContent = this.querySelector(
          `.navigation__category_item[data-url='${verticalCategory}']`
        ).dataset.name;
        this.navBackBtn.classList.add("navigation__headline_action--visible");
      }
    }

    window.requestAnimationFrame(() => {
      this.body.style.overflow = "hidden";
      this.pageOverlay.classList.add("page-overlay--filter-visible");
      this.setAttribute("open", "true");
    });
  }

  closeMenu() {
    window.requestAnimationFrame(() => {
      this.body.style.overflow = "initial";
      this.pageOverlay.classList.remove("page-overlay--filter-visible");
      this.setAttribute("open", "false");
    });
    this.resetNavigation();
  }

  initMobileMenu() {
    this.fetchMobileMenu().then((resp_text) => {
      window.requestAnimationFrame(() => {
        this.outerHTML = resp_text;
      });
    });
  }

  async fetchMobileMenu(max_retries = 5) {
    try {
      const response = await fetch("/ajax/mobile-menu/", {
        method: "GET",
        headers: {
          "Content-Type": "application/json",
          "X-CSRFToken": getCookie("csrftoken"),
        },
      });
      return await response.text();
    } catch (error) {
      if (max_retries === 1) throw error;
      return await this.fetchMobileMenu(max_retries - 1);
    }
  }
}
window.customElements.define("mobile-menu", MobileMenuComponent);
