import { MobileCategoricalFilterComponent } from "./mobile-categorical-filter-component";
import { MobileSortingComponent } from "./mobile-sorting-component";
import { getCookie } from "./utils";
import "../../../css/src/modules/mobile-filter-component.scss";

export class MobileFilterComponent extends HTMLElement {
  constructor() {
    super();
  }

  get hasActiveSubMenu() {
    return this.querySelector(
      ".mobile_filter__menu--sub[submenu-active='true']"
    )
      ? true
      : false;
  }

  connectedCallback() {
    // Check: Mobile Init?
    if (window.screen.width >= 1200 && window.screen.height >= 1200) {
      return;
    }

    // Attribute
    this.pageOverlay = document.querySelector("#page-overlay");
    this.sortButton = this.querySelector("#mobile-filter-sort-button");
    this.sortIcon = this.querySelector("#mobile-sort-icon");
    this.sortIconCounter = this.querySelector("#mobile-sort-icon-with-counter");
    this.filterButton = this.querySelector("#mobile-filter-filter-button");
    this.filterContainer = this.querySelector(
      ".mobile_filter__filter_container"
    );
    this.filterIcon = this.querySelector("#mobile-filter-icon");
    this.filterIconCounter = this.querySelector(
      "#mobile-filter-icon-with-counter"
    );
    this.filterIconCounterText = this.querySelector(
      "#mobile-filter-icon-count"
    );

    // Init
    if (this.getAttribute("init-filters") === "true") {
      this.initMobileInnerFilters();
      return;
    }
    this.initFilterComponents();

    // SubMenu: save-Event
    this.addEventListener("mobileFilterSubMenu:save", (evt) => {
      this.updateFilterMenu(evt);
    });

    // Sort-Button: touchstart
    this.sortButton.addEventListener("touchstart", (evt) => {
      evt.preventDefault();
      this.openSortMenu();
    });

    // Filter-Button: touchstart
    this.filterButton.addEventListener("touchstart", (evt) => {
      evt.preventDefault();
      this.openFilterMenu();
    });

    // PageOverlay: click
    this.pageOverlay.addEventListener("click", () => {
      if (this.pageOverlay.classList.contains("page-overlay--filter-visible")) {
        // Check: Aktives Sub-Menü?
        if (this.hasActiveSubMenu) {
          // Aktives Sub-Menü: Filter verändert?
          const activeSubMenu = this.querySelector(
            ".mobile_filter__menu--sub[submenu-active='true']"
          );
          if (activeSubMenu.checkModified()) {
            // Filter verändert: Filter speichern
            activeSubMenu.saveCloseMenu();
            this.closeFilterMenu();
          }
          {
            // Filter nicht verändert: Menü schließen
            activeSubMenu.closeMenu();
            this.closeFilterMenu();
          }
        } else {
          this.closeFilterMenu();
        }
      }
    });
  }

  initMobileInnerFilters() {
    this.fetchMobileFilter().then((resp_text) => {
      window.requestAnimationFrame(() => {
        this.filterContainer.innerHTML = resp_text;
        setTimeout(() => {
          this.connectedCallback();
        }, 0);
      });
    });
    this.setAttribute("init-filters", "false");
  }

  initFilterComponents() {
    this.deleteButton = this.querySelector(
      ".mobile_filter__filter_headline_action_text--delete-all"
    );
    this.mainMenu = this.querySelector(".mobile_filter__menu--main");
    this.mainMenuItems = this.querySelectorAll(
      ".mobile_filter__menu--main .mobile_filter__filter_item"
    );
    this.mainMenuResultButton = this.querySelector(
      ".mobile_filter__filter_action--show-results"
    );
    this.subMenus = {};
    this.querySelectorAll(".mobile_filter__menu--sub").forEach((elt) => {
      let filterSlug = elt.getAttribute("filter-slug");
      if (filterSlug) {
        this.subMenus[filterSlug] = elt;
      }
    });

    // Aktive Filter + Count
    this.updateFilterMenuProperties();

    // Menu Items: Update Filter Icon
    this.updateFilterMenuIcons();

    // Delete All-Button: click
    this.deleteButton.addEventListener("click", () => {
      parameters.removeAllParameters();
      this.updateFilterMenu();
    });

    // Main Menu Items: click
    this.mainMenuItems.forEach((elt) => {
      elt.addEventListener("click", () => {
        this.subMenus[elt.getAttribute("filter-slug")].setAttribute(
          "submenu-active",
          "true"
        );
      });
    });

    // Main Menu Ergebnisse-Button: click
    this.mainMenuResultButton.addEventListener("click", () => {
      this.closeFilterMenu();
    });
  }

  updateFilterMenuProperties() {
    const activeSorting =
      this.subMenus["sort"].getAttribute("has-active-filters") === "true"
        ? true
        : false;
    const activeFilterCount = parameters.activeFilterCount();
    if (activeSorting) {
      this.mainMenu.setAttribute("has-active-sorting", "true");
      this.sortIcon.classList.remove("mobile_filter__icon--visible");
      this.sortIconCounter.classList.add("mobile_filter__icon--visible");
    } else {
      this.mainMenu.setAttribute("has-active-sorting", "false");
      this.sortIcon.classList.add("mobile_filter__icon--visible");
      this.sortIconCounter.classList.remove("mobile_filter__icon--visible");
    }
    if (activeFilterCount) {
      this.mainMenu.setAttribute("has-active-filters", "true");
      this.filterIcon.classList.remove("mobile_filter__icon--visible");
      this.filterIconCounter.classList.add("mobile_filter__icon--visible");
      this.filterIconCounterText.textContent = activeFilterCount;
    } else {
      this.mainMenu.setAttribute("has-active-filters", "false");
      this.filterIcon.classList.add("mobile_filter__icon--visible");
      this.filterIconCounter.classList.remove("mobile_filter__icon--visible");
    }
  }

  updateFilterMenuIcons() {
    this.mainMenuItems.forEach((elt) => {
      let filterSlug = elt.getAttribute("filter-slug");
      if (filterSlug) {
        let subMenu = this.subMenus[filterSlug];
        if (subMenu.getAttribute("has-active-filters") === "true") {
          elt
            .querySelector(".mobile_filter__icon--menu-item")
            .classList.add("mobile_filter__icon--visible");
        } else {
          elt
            .querySelector(".mobile_filter__icon--menu-item")
            .classList.remove("mobile_filter__icon--visible");
        }
      }
    });
  }

  updateFilterMenu(event) {
    const onlyUpdateMenuIcons = ["sort"];

    // Check: Event-Target
    if (
      event !== undefined &&
      onlyUpdateMenuIcons.includes(event.target.getAttribute("filter-slug"))
    ) {
      // Nur MenüIcons updaten
      this.updateFilterMenuIcons();
      this.updateFilterMenuProperties();
      return;
    }

    // MobileFilters fetchen
    this.fetchMobileFilter().then((resp_text) => {
      window.requestAnimationFrame(() => {
        this.filterContainer.innerHTML = resp_text;
        setTimeout(() => {
          this.initFilterComponents();
        }, 0);
      });
    });
  }

  async fetchMobileFilter(max_retries = 5) {
    try {
      const response = await fetch("/ajax/mobile-filters/", {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
          "X-CSRFToken": getCookie("csrftoken"),
        },
        body: JSON.stringify(parameters.activeParametersDict),
      });
      return await response.text();
    } catch (error) {
      if (max_retries === 1) throw error;
      return await this.fetchMobileFilter(max_retries - 1);
    }
  }

  openSortMenu() {
    // Sorting-SubMenu: Aktiv setzen
    this.subMenus["sort"].setAttribute("submenu-active", "true");

    // Filter-Menü öffnen
    this.openFilterMenu();
  }

  openFilterMenu() {
    window.requestAnimationFrame(() => {
      // <body>-Overflow deaktivieren
      document.querySelector("body").style.overflow = "hidden";

      // PageOverlay aktivieren
      this.pageOverlay.classList.add("page-overlay--filter-visible");

      // Menü öffnen
      this.filterContainer.classList.add(
        "mobile_filter__filter_container--active"
      );
    });

    // import MobilePriceFilterComponent
    setTimeout(() => {
      import(
        /* webpackChunkName: "mobile-price-filter-component" */
        /* webpackMode: "lazy" */
        /* webpackPrefetch: true */
        "./mobile-price-filter-component"
      );
    }, 0);
  }

  closeFilterMenu() {
    if (parameters.checkIfChangedAny()) {
      parameters.reloadPage();
    } else {
      window.requestAnimationFrame(() => {
        // <body>-Overflow deaktivieren
        document.querySelector("body").style.overflow = "initial";

        // PageOverlay deaktivieren
        this.pageOverlay.classList.remove("page-overlay--filter-visible");

        // Menü schließen
        this.filterContainer.classList.remove(
          "mobile_filter__filter_container--active"
        );
      });
    }
  }
}
window.customElements.define("mobile-filter", MobileFilterComponent);
