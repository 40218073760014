import { getCookie } from "./utils";
import "../../../css/src/modules/filter-container-component.scss";

export class FilterContainer extends HTMLElement {
  constructor() {
    super();
  }

  connectedCallback() {
    this.moreFiltersBtn = this.querySelector(".filter__more-filters");
    if (this.moreFiltersBtn) {
      this.moreFiltersBtn.addEventListener(
        "click",
        this.showMoreFilters.bind(this)
      );
    }

    if (this.getAttribute("init-filters") === "true") {
      if (window.screen.width >= 1200 || window.screen.height >= 1200) {
        this.initDesktopFilters();
      }
    }
  }

  showMoreFilters() {
    window.requestAnimationFrame(() => {
      this.moreFiltersBtn.classList.add("filter__more-filters--hidden");
      this.querySelectorAll(".filter__item-container--hidden").forEach(
        (item) => {
          item.classList.remove("filter__item-container--hidden");
        }
      );
    });
  }

  async fetchDesktopFilters(max_retries = 5) {
    try {
      const response = await fetch("/ajax/desktop-filters/", {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
          "X-CSRFToken": getCookie("csrftoken"),
        },
        body: JSON.stringify(parameters.activeParametersDict),
      });
      return await response.text();
    } catch (error) {
      if (max_retries === 1) throw error;
      return await this.fetchDesktopFilters(max_retries - 1);
    }
  }

  initDesktopFilters() {
    this.fetchDesktopFilters().then((response) => {
      window.requestAnimationFrame(() => {
        this.outerHTML = response;
      });
    });
  }
}
window.customElements.define("filter-container", FilterContainer);
