import "../../../css/src/modules/filter-component.scss";

export class FilterComponent extends HTMLElement {
  constructor() {
    super();
  }

  connectedCallback() {
    // Check: Filter-Container initialisiert?
    if (this.parentElement.getAttribute("init-filters") === "true") {
      return;
    }

    this.filterTitle = this.querySelector(".filter__title");
    this.filterContentContainer = this.querySelector(
      ".filter__content-container"
    );
    this.filterActiveCount = this.querySelector(".filter__title-active-count");

    // Events
    this.filterTitle.addEventListener("click", () => {
      window.requestAnimationFrame(() => {
        if (this.hasAttribute("open")) {
          this.removeAttribute("open");
          this.handleClose();
        } else {
          this.handleOpen();
          this.setAttribute("open", "");
        }
      });
    });

    this.initFilterEvents();
  }

  initFilterEvents() {
    // Event-Handler for filter-items
    this.filterContentContainer.addEventListener("click", (event) => {
      event.preventDefault();
      let clickedOption = event.target.closest(".filter__item");

      if (clickedOption) {
        if (clickedOption.dataset.optionActive === "true") {
          parameters.removeParameter(
            this.dataset.filterSlug,
            clickedOption.dataset.optionSlug
          );
        } else {
          parameters.updateParameter(
            this.dataset.filterSlug,
            clickedOption.dataset.optionSlug,
            clickedOption.dataset.optionValue || "",
            false
          );
        }
      }
    });

    // Event-Handler for reset-button
    this.filterButtonReset = this.querySelector(".filter__content-btn--reset");
    this.filterButtonReset.addEventListener("click", (event) => {
      if (
        this.filterButtonReset.classList.contains(
          "filter__content-btn--visible"
        )
      ) {
        this.querySelectorAll(
          ".filter__item[data-option-active='true']"
        ).forEach((option) => {
          option.dataset.optionActive = "false";
        });
        parameters.removeParameter(this.dataset.filterSlug, "", true);
        this.removeAttribute("open");
        this.updateActiveFilterCount(0);
      }
    });

    // Event-Handler for save-button
    this.filterButtonSave = this.querySelector(".filter__content-btn--save");
    this.filterButtonSave.addEventListener("click", (event) => {
      if (
        this.filterButtonSave.classList.contains("filter__content-btn--visible")
      ) {
        parameters.reloadPage();
        this.removeAttribute("open");
      }
    });

    // Event-Handler for Parameter-Updates
    document.addEventListener("parameter-update", (event) => {
      if (event.detail.parameterClass === this.dataset.filterSlug) {
        this.handleParameterUpdate(event.detail);
      }
    });

    // Event-Handler for Parameter-Removals
    document.addEventListener("parameter-remove", (event) => {
      if (event.detail.parameterClass === this.dataset.filterSlug) {
        this.handleParameterRemove(event.detail);
      }
    });
  }

  handleParameterUpdate(detail) {
    const itemSelector = `.filter__item[data-option-slug='${detail.parameterKey}']`;
    const clickedOption = this.querySelector(itemSelector);
    clickedOption.dataset.optionActive = "true";
    this.updateActiveFilterCount(detail.activeParameterCount);
    this.checkButtonVisibility();
  }

  handleParameterRemove(detail) {
    if (detail["parameterKey"] !== "") {
      const itemSelector = `.filter__item[data-option-slug='${detail.parameterKey}']`;
      const clickedOption = this.querySelector(itemSelector);
      clickedOption.dataset.optionActive = "false";
    } else {
      this.querySelectorAll(".filter__item[data-option-active='true']").forEach(
        (option) => {
          option.dataset.optionActive = "false";
        }
      );
    }
    this.updateActiveFilterCount(detail.activeParameterCount);
    this.checkButtonVisibility();
  }

  updateActiveFilterCount(activeFilterCount) {
    if (activeFilterCount != this.dataset.activeFilterCount) {
      if (activeFilterCount > 0) {
        this.filterActiveCount.classList.add(
          "filter__title-active-count--visible"
        );
        this.filterActiveCount.innerText = activeFilterCount;
      } else {
        this.filterActiveCount.classList.remove(
          "filter__title-active-count--visible"
        );
      }
      this.dataset.activeFilterCount = activeFilterCount;
    }
  }

  outsideClickHandler(event) {
    if (!this.contains(event.target) && this.hasAttribute("open")) {
      this.removeAttribute("open");
      this.handleClose();
    }
  }

  handleOpen() {
    // Event-Handler outside click
    document.addEventListener("click", this.outsideClickHandler.bind(this));

    // Calculate if the element is in the left half or right half of the screen
    const rect = this.getBoundingClientRect();
    const isLeft = rect.left < window.innerWidth / 2;
    if (!isLeft) {
      this.filterContentContainer.classList.add(
        "filter__content-container--align-right"
      );
    }

    // Button Visibility
    this.checkButtonVisibility();
  }

  handleClose() {
    // Remove Event-Handler outside click
    document.removeEventListener("click", this.outsideClickHandler.bind(this));

    // Remove alignment
    this.filterContentContainer.classList.remove(
      "filter__content-container--align-right"
    );

    // Compare both sets for equality
    if (parameters.checkIfChanged(this.dataset.filterSlug)) {
      parameters.reloadPage();
    }
  }

  checkButtonVisibility() {
    if (parameters.checkIfChanged(this.dataset.filterSlug)) {
      this.filterButtonSave.classList.add("filter__content-btn--visible");
    } else {
      this.filterButtonSave.classList.remove("filter__content-btn--visible");
    }

    if (this.dataset.activeFilterCount > 0) {
      this.filterButtonReset.classList.add("filter__content-btn--visible");
    } else {
      this.filterButtonReset.classList.remove("filter__content-btn--visible");
    }
  }
}

export class CategoricalFilterComponent extends FilterComponent {
  connectedCallback() {
    super.connectedCallback();
  }
}
window.customElements.define("categorical-filter", CategoricalFilterComponent);

export class RankingFilterComponent extends FilterComponent {
  connectedCallback() {
    super.connectedCallback();
  }
}
window.customElements.define("ranking-filter", RankingFilterComponent);
