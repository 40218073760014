import "../../../css/src/modules/recommendations-component.scss";

export class RecommendationsComponent extends HTMLElement {
  constructor() {
    super();
  }

  connectedCallback() {
    this.productContainer = this.querySelector(".product__container");
    this.firstProductGroupID = document.querySelector("product-group").dataset.pid;

    // Fetch recommendations
    if (this.firstProductGroupID) {
      this.fetchRecommendations();
    }
  }

  async fetchRecommendations(maxRetries = 5) {
    try {
      const response = await fetch(
        `/ajax/recommendations/product_group/${this.firstProductGroupID}/`
      );
      const data = await response.text();

      if (response.status === 200) {
        window.requestAnimationFrame(() => {
            this.productContainer.insertAdjacentHTML("beforeend", data);
        });
      } else {
        throw new Error(data);
      }
    } catch (error) {
      if (maxRetries > 0) {
        await this.fetchRecommendations(maxRetries - 1);
      } else {
        throw error;
      }
    }
  }

}
window.customElements.define("recommendations-container", RecommendationsComponent);
