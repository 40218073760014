import "../../../css/src/modules/quick-link-component.scss";

export class QuickLinkComponent extends HTMLElement {
  constructor() {
    super();
  }

  connectedCallback() {
    this.scrollHandler = this.scrollHandler.bind(this);

    // Scroll Event
    window.addEventListener("scroll", this.scrollHandler, {
      passive: true,
    }
    );
  }

  active() {
    // Check Viewport width
    if (window.innerWidth > 600) {
      return;
    }

    this.classList.add("active");
    document.body.classList.add("overflow-hidden");

    this.addEventListener("click", this.clickHandler.bind(this));
  }

  deactive() {
    this.classList.remove("active");
    document.body.classList.remove("overflow-hidden");

    this.removeEventListener("click", this.clickHandler.bind(this));
  }

  clickHandler(e) {
    // if (
    //   e.target.classList.contains("quick-link__close__icon") ||
    //   e.target.classList.contains("quick-link")
    // ) {
    //   this.deactive();
    // }
    this.deactive();
  }

  scrollHandler(e) {
    if (window.scrollY > 50) {
      this.active();

      // Remove Event Listener
      window.removeEventListener("scroll", this.scrollHandler, {
        passive: true,
      });
    }
  }
}
window.customElements.define("quick-link", QuickLinkComponent);
