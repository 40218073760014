import MmenuLight from "mmenu-light";
import "../../../../../node_modules/mmenu-light/dist/mmenu-light.css";

export class MobileHeaderComponent extends HTMLElement {
  constructor() {
    super();
  }

  connectedCallback() {
    // Attribute
    this.pageOverlay = document.querySelector(".page-overlay");
    this.headerContainer = document.querySelector(".header__container");
    this.body = document.querySelector("body");
    this.searchContainer = document.querySelector(".header__search-container");
    this.searchForm = this.searchContainer.querySelector("#searchForm");
    this.searchInput = document.querySelector("#autoComplete");
    this.searchResultTemplate =
      this.searchContainer.querySelector("#search-result");
    this.searchBackBtn = this.searchContainer.querySelector(
      ".header__search-form-svg--back"
    );
    this.searchDeleteBtn = this.searchContainer.querySelector(
      ".header__search-form-svg--delete"
    );
    this.searchResultList = this.searchContainer.querySelector(
      ".header__search-result-list"
    );
    this.initSearchInputValue = this.searchInput.value;

    // Event-Handler: Suche
    this.querySelector(".header__mobile-btn--search").addEventListener(
      "click",
      () => {
        this.toggleSearchBar();
        if (
          this.searchContainer.classList.contains(
            "header__search-container--visible"
          )
        ) {
          this.clearSearchResults();
        }
      }
    );

    // Event-Handler: Page-Overlay -> Suche schließen
    this.pageOverlay.addEventListener("click", () => {
      // Check: --filter-visible => handled by MobileFilterComponent
      if (
        !this.pageOverlay.classList.contains("page-overlay--filter-visible")
      ) {
        this.searchDeleteBtn.classList.add("header__search-form-svg--hidden");
        this.clearSearchResults();
        this.closeSearchBar();
      }
    });

    // searchBackBtn: click-Event
    this.searchBackBtn.addEventListener("click", () => {
      this.searchDeleteBtn.classList.add("header__search-form-svg--hidden");
      this.clearSearchResults();
      this.closeSearchBar();
    });

    // searchDeleteBtn: click-Event
    this.searchDeleteBtn.addEventListener("click", () => {
      this.searchInput.value = "";
      this.searchDeleteBtn.classList.add("header__search-form-svg--hidden");
      this.clearSearchResults();
    });

    // searchInput: input-Event
    this.searchInput.addEventListener("input", (evt) => {
      if (this.searchInput.value.length >= 2) {
        this.searchDeleteBtn.classList.remove(
          "header__search-form-svg--hidden"
        );
        this.updateSearchResults();
      } else {
        this.searchDeleteBtn.classList.add("header__search-form-svg--hidden");
        if (evt.inputType === "deleteContentBackward") {
          this.clearSearchResults();
        }
      }
    });

    // searchForm: submit-Event
    this.searchForm.addEventListener("submit", (evt) => {
      // searchInput: <= 2 Zeichen => Abbruch
      if (this.searchInput.value.length <= 2) {
        evt.preventDefault();
      }
    });

    // searchResultList: click-Event
    this.searchResultList.addEventListener("click", (evt) => {
      const searchResult = evt.target.closest(".header__search-result");
      if (evt.target.classList.contains("header__search-result-icon")) {
        // Autocomplete-Vorschlag übernehmen
        this.searchInput.value = searchResult.dataset.text;
        this.updateSearchResults();
      } else {
        // Autocomplete: URL aufrufen
        document.location.assign(searchResult.dataset.url);
      }
    });
  }

  updateSearchResults() {
    const encSearchValue = encodeURIComponent(this.searchInput.value);
    const fetchUrl = `/ajax/autocomplete/${encSearchValue}/`;
    this.fetchSearchResults(fetchUrl).then((resp_json) => {
      const inputPatternArray = this.searchInput.value
        .toLowerCase()
        .split(" ")
        .filter((elt) => elt.length >= 2)
        .map((elt) => [elt, RegExp(elt, "ig")]);
      const docFragment = document.createDocumentFragment();
      resp_json.autocomplete_results.forEach((elt) => {
        const searchResultElt = this.searchResultTemplate.content.querySelector(
          ".header__search-result"
        );
        let searchText = elt.searchText;
        inputPatternArray.forEach((x) => {
          const inputText = x[0];
          const pattern = x[1];
          searchText = searchText.replace(
            pattern,
            `<span class="header__search-result-marker">${inputText}</span>`
          );
        });
        searchResultElt.dataset.text = elt.searchText;
        searchResultElt.dataset.url = elt.url;
        searchResultElt.querySelector(".header__search-result-text").innerHTML =
          searchText;
        const eltClone = document.importNode(searchResultElt, true);
        docFragment.appendChild(eltClone);
      });
      window.requestAnimationFrame(() => {
        this.searchResultList.innerHTML = "";
        this.searchResultList.appendChild(docFragment);
        this.searchResultList.classList.add(
          "header__search-result-list--visible"
        );
      });
    });
  }

  clearSearchResults() {
    window.requestAnimationFrame(() => {
      this.searchResultList.classList.remove(
        "header__search-result-list--visible"
      );
      this.searchResultList.innerHTML = "";
    });
  }

  async fetchSearchResults(fetchUrl, max_retries = 3) {
    try {
      const resp = await fetch(fetchUrl);
      if (resp.ok) {
        return await resp.json();
      }
    } catch (err) {
      if (max_retries === 1) throw err;
      return await this.fetchSearchResults(fetchUrl, max_retries - 1);
    }
  }

  toggleSearchBar() {
    window.requestAnimationFrame(() => {
      this.pageOverlay.classList.toggle("page-overlay--visible");
      if (this.pageOverlay.classList.contains("page-overlay--visible")) {
        document.querySelector(".header_top").style.display = "none";
      }
      this.searchContainer.classList.toggle(
        "header__search-container--visible"
      );
      this.headerContainer.classList.toggle(
        "header__container--non-transparent"
      );
      if (
        this.searchContainer.classList.contains(
          "header__search-container--visible"
        )
      ) {
        this.body.style.overflow = "hidden";
      } else {
        this.body.style.overflow = "initial";
      }
      this.searchContainer.addEventListener(
        "transitionend",
        (evt) => {
          if (
            this.searchContainer.classList.contains(
              "header__search-container--visible"
            )
          ) {
            window.requestAnimationFrame(() => {
              this.searchInput.value = "";
              this.searchInput.focus();
            });
          }
        },
        { once: true }
      );
    });
  }

  closeSearchBar() {
    window.requestAnimationFrame(() => {
      document.querySelector(".header_top").style.display = "block";
      this.searchInput.value = this.initSearchInputValue;
      this.pageOverlay.classList.remove("page-overlay--visible");
      this.searchContainer.classList.remove(
        "header__search-container--visible"
      );
      this.headerContainer.classList.remove(
        "header__container--non-transparent"
      );
      this.body.style.overflow = "initial";
    });
  }
}
window.customElements.define("mobile-header", MobileHeaderComponent);
