export class LinkListComponent extends HTMLElement {
  constructor() {
    super();
  }

  connectedCallback() {
    this.deepLinksList = this.querySelector(".deeplinks__list");
    this.cursorLeft = this.querySelector(".deeplinks__cursor--left");
    this.cursorRight = this.querySelector(".deeplinks__cursor--right");

    if (this.deepLinksList) {
      // Cursors: Click-Handler
      this.addCursorClickHandler(this.cursorLeft);
      this.addCursorClickHandler(this.cursorRight);
      // Initialer Status
      this.updateCursorStatus();
    }
  }

  addCursorClickHandler(target) {
    target.addEventListener("click", (event) => {
      const scrollPositions = this.getScrollPosition();
      const scrollOffset = scrollPositions.clientWidth;

      if (event.target === this.cursorRight) {
        this.deepLinksList.scrollLeft += scrollOffset;
        this.updateCursorStatus();
      } else {
        this.deepLinksList.scrollLeft -= scrollOffset;
        this.updateCursorStatus();
      }
    });
  }

  getScrollPosition() {
    const xscrollLeft = this.deepLinksList.scrollLeft;
    const xscrollWidth = this.deepLinksList.scrollWidth;
    const xclientWidth = this.deepLinksList.clientWidth;
    const xscrollLeftMax = xscrollWidth - xclientWidth;

    return {
      scrollLeft: xscrollLeft,
      scrollLeftMax: xscrollLeftMax,
      scrollWidth: xscrollWidth,
      clientWidth: xclientWidth,
    };
  }

  updateCursorStatus() {
    const scrollPositions = this.getScrollPosition();

    if (scrollPositions.scrollLeftMax > scrollPositions.scrollLeft) {
      this.cursorRight.classList.add("deeplinks__cursor--visible");
    } else {
      this.cursorRight.classList.remove("deeplinks__cursor--visible");
    }

    if (scrollPositions.scrollLeft > 0) {
      this.cursorLeft.classList.add("deeplinks__cursor--visible");
    } else {
      this.cursorLeft.classList.remove("deeplinks__cursor--visible");
    }
  }
}
window.customElements.define("link-list", LinkListComponent);
