import { getCookie, deleteCookie } from "./utils";

export class ProductGroupComponent extends HTMLElement {
  constructor() {
    super();
  }

  connectedCallback() {
    // Like-Button
    this.productLike = this.querySelector(".product__like");
    const wishListProductGroupIds = localStorage.getItem(
      "wishListproductGroupIds"
    );
    if (wishListProductGroupIds) {
      const productGroupIds = JSON.parse(wishListProductGroupIds);
      if (productGroupIds.includes(Number(this.getAttribute("data-pid")))) {
        this.productLike.classList.add("product__like--active");
      }
    }

    // ProductGroup: Click-Event
    this.addEventListener("mousedown", (evt) => {
      // paid_visitor
      const paidVisitor = getCookie("paid_visitor");
      const link = this.querySelector("a");
      if (paidVisitor === "1") {
        link.setAttribute("target", "_blank");
      }
    });

    // ProductGroup Like: Click-Event
    this.productLike.addEventListener("click", () => {
      if (this.productLike.classList.contains("product__like--active")) {
        this.removeFromWishList();
      } else {
        this.addToWishList();
      }
    });
  }

  addToWishList() {
    // ProductGroup
    this.productLike.classList.add("product__like--active");
    this.dispatchEvent(
      new Event("productGroup:addToWishList", {
        detail: {
          productGroupId: this.dataset.pid,
        },
        bubbles: true,
      })
    );
    this._fetchRequest("POST", 3);
  }

  removeFromWishList() {
    // ProductGroup
    this.productLike.classList.remove("product__like--active");
    this.dispatchEvent(
      new Event("productGroup:removedFromWishList", {
        detail: {
          productGroupId: this.dataset.pid,
        },
        bubbles: true,
      })
    );
    this._fetchRequest("DELETE", 3);
  }

  async _fetchRequest(method, n) {
    try {
      return await fetch("/ajax/wishlist", {
        method: method,
        credentials: "same-origin",
        headers: {
          "Content-Type": "application/json",
          "X-CSRFToken": getCookie("csrftoken"),
        },
        body: JSON.stringify({
          productGroupId: this.dataset.pid,
        }),
      });
    } catch (err) {
      if (n === 1) throw err;
      return await _fetchRequest(method, n - 1);
    }
  }
}
window.customElements.define("product-group", ProductGroupComponent);
