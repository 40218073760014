import "../../../css/src/modules/coupon-card-component.scss";

export class CouponCardComponent extends HTMLElement {
  constructor() {
    super();

    this.showDetails = null;
    this.details = null;
    this.detailsOpen = false;
    this.couponId = this.dataset.cid;
    this.merchant = this.dataset.merchant;
  }

  connectedCallback() {
    this.showDetails = this.querySelector(".show-details");
    this.details = this.querySelector(".coupon__item__details");

    // Add event listener to show details button
    this.showDetails.addEventListener("click", (e) => {
      e.stopPropagation();
      this.toggleDetails();
    });

    // Add event listener to coupon card
    this.addEventListener("click", (e) => {
      this.revealCoupon();
    });
  }

  revealCoupon() {
    let targetUrl = "/gutscheine/" + this.merchant + "/r/" + this.couponId + "/";
    let couponUrl = "/gutscheine/" + this.merchant + "/" + this.couponId + "/";
    window.open(couponUrl, "_blank");
    setTimeout(() => {
      window.location = targetUrl;
    }, 0);
  }

  toggleDetails() {
    this.details.classList.toggle("coupon__item__details--open");
    this.detailsOpen = !this.detailsOpen;
    if (this.detailsOpen) {
      this.showDetails.querySelector(".show-details__text").innerHTML =
        "Weniger Details";
      this.showDetails
        .querySelector(".coupon__item__carrot")
        .classList.remove("coupon__item__carrot--down");
      this.showDetails
        .querySelector(".coupon__item__carrot")
        .classList.add("coupon__item__carrot--up");
    } else {
      this.showDetails.querySelector(".show-details__text").innerHTML =
        "Mehr Details";
      this.showDetails
        .querySelector(".coupon__item__carrot")
        .classList.remove("coupon__item__carrot--up");
      this.showDetails
        .querySelector(".coupon__item__carrot")
        .classList.add("coupon__item__carrot--down");
    }
  }
}
window.customElements.define("coupon-card-component", CouponCardComponent);
