import { MobileFilterSubMenuComponent } from "./mobile-filter-submenu-component";

export class MobileSortingComponent extends MobileFilterSubMenuComponent {
  constructor() {
    super();
  }

  connectedCallback() {
    super.connectedCallback();

    // Attribute
    this.filterSlug = this.getAttribute("filter-slug");
    this.filterOptionContainer = this.querySelector("#filter-option-container");
    this.defaultSortingOption = this.filterOptionContainer.querySelector(
      ".mobile_filter__filter_option[data-slug='relevance']"
    );
    this.initActiveSortingOption = this.filterOptionContainer.querySelector(
      ".mobile_filter__filter_option[data-active='true']"
    );

    // Filter-Options-Container: click-Event
    this.filterOptionContainer.addEventListener("click", (evt) => {
      if (evt.target !== this.filterOptionContainer) {
        this.updateFilter(evt.target);
      }
    });

    this.checkActiveFilters();
  }

  get activeSortingOption() {
    return this.filterOptionContainer.querySelector(
      ".mobile_filter__filter_option[data-active='true']"
    );
  }

  saveCloseMenu() {
    this.saveFilters();
    this.setAttribute("submenu-active", "false");
    this.dispatchEvent(
      new Event("mobileFilterSubMenu:save", { bubbles: true })
    );

    // Button wieder zurücksetzen
    this.filtersNotModified();
  }

  checkActiveFilters() {
    const activeSortingOption = this.activeSortingOption;
    if (activeSortingOption.dataset.slug !== "relevance") {
      this.setAttribute("has-active-filters", "true");
    } else {
      this.setAttribute("has-active-filters", "false");
    }
  }

  checkModified() {
    if (
      this.initActiveSortingOption.dataset.slug ===
      this.activeSortingOption.dataset.slug
    ) {
      this.filtersNotModified();
      return false;
    } else {
      this.filtersModified();
      return true;
    }
  }

  updateFilter(target) {
    const sortingOption = target.closest(".mobile_filter__filter_option");
    if (sortingOption.dataset.active === "true") {
      // click ignorieren: Immer eine Option aktiv
      return;
    } else {
      // Aktive Option wechseln
      this.activeSortingOption.dataset.active = "false";
      sortingOption.dataset.active = "true";
    }
    this.checkModified();
    this.checkActiveFilters();
  }

  resetFilters() {
    if (this.activeSortingOption !== this.defaultSortingOption) {
      this.activeSortingOption.dataset.active = "false";
      this.defaultSortingOption.dataset.active = "true";
    }
    this.setAttribute("has-active-filters", "false");
    this.checkModified();
  }

  saveFilters() {
    // Sorting-Parameter updaten
    parameters.updateParameter(
      this.filterSlug,
      this.filterSlug,
      this.activeSortingOption.dataset.slug
    );

    // initSortingOption aktualisieren
    this.initActiveSortingOption = this.activeSortingOption;
  }
}
window.customElements.define("mobile-sorting", MobileSortingComponent);
