import { getCookie } from "./utils";

import "../../../css/src/modules/product-group-detail-component.scss";

export class ProductGroupDetailComponent extends HTMLElement {
  constructor() {
    super();
  }

  connectedCallback() {
    this.galleryThumbItems = this.querySelectorAll(
      ".product_group_detail__gallery_thumbs_item"
    );
    this.galleryThumbContainer = this.querySelector(
      ".product_group_detail__gallery_thumbs"
    );
    this.recomContainer = this.querySelector(
      ".product_group_detail__recommendations_container"
    );
    this.productGroupDescription = this.querySelector(
      ".product_group_detail__info_description"
    );

    // Like-Button
    this.productLike = this.querySelector(".product__like");
    const wishListProductGroupIds = localStorage.getItem(
      "wishListproductGroupIds"
    );
    if (wishListProductGroupIds) {
      const productGroupIds = JSON.parse(wishListProductGroupIds);
      if (productGroupIds.includes(Number(this.getAttribute("data-pid")))) {
        this.productLike.classList.add("product__like--active");
      }
    }
    this.productLike.addEventListener("click", () => {
      if (this.productLike.classList.contains("product__like--active")) {
        this.removeFromWishList();
      } else {
        this.addToWishList();
      }
    });

    // Check Description Length
    if (this.productGroupDescription) {
      this.checkDescriptionLength();
    }

    // Check Gallery Arrows
    this.checkGalleryArrowVisibility();

    // Event Listener: Gallery Main Image
    this.galleryMain = this.querySelector(
      ".product_group_detail__gallery_main"
    );
    this.galleryMainImage = this.querySelector(
      ".product_group_detail__gallery_main_image"
    );
    this.galleryMainImage.addEventListener("load", () => {
      let pixelColor = this.getTopLeftPixel(this.galleryMainImage);
      let isWhite =
        (pixelColor["red"] + pixelColor["green"] + pixelColor["blue"]) / 3 >=
        253;
      this.galleryMainImage.style =
        "mix-blend-mode: " + (isWhite ? "multiply" : "normal");
    });
    if (this.galleryMainImage.complete) {
      let pixelColor = this.getTopLeftPixel(this.galleryMainImage);
      let isWhite =
        (pixelColor["red"] + pixelColor["green"] + pixelColor["blue"]) / 3 >=
        253;
      this.galleryMainImage.style =
        "mix-blend-mode: " + (isWhite ? "multiply" : "normal");
    }

    // Event Listener: Thumbs Hover
    this.galleryThumbItems.forEach((thumb) => {
      thumb.addEventListener("mouseenter", this.thumbHover.bind(this));
    });

    // Event Listener: Thumbs Click
    this.galleryThumbItems.forEach((thumb) => {
      thumb.addEventListener("click", this.thumbHover.bind(this));
    });

    // Event Listener: Prev Arrow
    this.querySelector(
      ".product_group_detail__gallery_main_arrow--prev"
    ).addEventListener("click", this.prevImage.bind(this));

    // Event Listener: Next Arrow
    this.querySelector(
      ".product_group_detail__gallery_main_arrow--next"
    ).addEventListener("click", this.nextImage.bind(this));

    // Fetch Recommendations
    this.fetchRecommendations();
  }

  getTopLeftPixel(img) {
    // Create a new canvas and get its context
    var canvas = document.createElement("canvas");
    var ctx = canvas.getContext("2d");

    // Set the canvas dimensions to the same as the image
    canvas.width = img.width;
    canvas.height = img.height;

    // Draw the image onto the canvas
    ctx.drawImage(img, 0, 0);

    // Get the image data from the canvas
    var imageData = ctx.getImageData(0, 0, 1, 1).data;

    // The top left pixel's data is at the start of the array
    var topLeftPixel = {
      red: imageData[0],
      green: imageData[1],
      blue: imageData[2],
      alpha: imageData[3],
    };

    return topLeftPixel;
  }

  addToWishList() {
    // ProductGroup
    this.productLike.classList.add("product__like--active");
    this.dispatchEvent(
      new Event("productGroup:addToWishList", {
        detail: {
          productGroupId: this.dataset.pid,
        },
        bubbles: true,
      })
    );
    this._fetchRequest("POST", 3);
  }

  removeFromWishList() {
    // ProductGroup
    this.productLike.classList.remove("product__like--active");
    this.dispatchEvent(
      new Event("productGroup:removedFromWishList", {
        detail: {
          productGroupId: this.dataset.pid,
        },
        bubbles: true,
      })
    );
    this._fetchRequest("DELETE", 3);
  }

  async _fetchRequest(method, n) {
    try {
      return await fetch("/ajax/wishlist", {
        method: method,
        credentials: "same-origin",
        headers: {
          "Content-Type": "application/json",
          "X-CSRFToken": getCookie("csrftoken"),
        },
        body: JSON.stringify({
          productGroupId: this.dataset.pid,
        }),
      });
    } catch (err) {
      if (n === 1) throw err;
      return await _fetchRequest(method, n - 1);
    }
  }

  calculateImageResolution() {
    const screenWidth = window.innerWidth;
    const screenHeight = window.innerHeight;
    const devicePixelRatio = window.devicePixelRatio;

    const imageResolution =
      screenWidth + ":" + screenHeight + ":" + devicePixelRatio;
    return imageResolution;
  }

  preloadImage(url) {
    const image = new Image();
    image.src = url;
  }

  checkDescriptionLength() {
    const descriptionText = this.productGroupDescription.querySelector(
      ".product_group_detail__info_description_text"
    );
    const descriptionMore = this.productGroupDescription.querySelector(
      ".product_group_detail__info_description_more"
    );

    if (descriptionText.scrollHeight > descriptionText.offsetHeight) {
      descriptionMore.classList.add(
        "product_group_detail__info_description_more--visible"
      );

      descriptionMore.addEventListener("click", () => {
        descriptionText.classList.add(
          "product_group_detail__info_description_text--no-line-clamp"
        );
        descriptionMore.classList.remove(
          "product_group_detail__info_description_more--visible"
        );
      });
    }
  }

  checkGalleryArrowVisibility() {
    const arrowPrev = this.querySelector(
      ".product_group_detail__gallery_main_arrow--prev"
    );
    const arrowNext = this.querySelector(
      ".product_group_detail__gallery_main_arrow--next"
    );
    const mainImage = this.querySelector(
      ".product_group_detail__gallery_main_image"
    );

    // Single Image
    if (this.galleryThumbItems.length <= 1) {
      arrowPrev.classList.add(
        "product_group_detail__gallery_main_arrow--hidden"
      );
      arrowNext.classList.add(
        "product_group_detail__gallery_main_arrow--hidden"
      );
    } else {
      arrowPrev.classList.remove(
        "product_group_detail__gallery_main_arrow--hidden"
      );
      arrowNext.classList.remove(
        "product_group_detail__gallery_main_arrow--hidden"
      );
    }

    // Previous Image?
    if (mainImage.dataset.idx == "0") {
      arrowPrev.classList.add(
        "product_group_detail__gallery_main_arrow--hidden"
      );
    } else {
      arrowPrev.classList.remove(
        "product_group_detail__gallery_main_arrow--hidden"
      );
    }

    // Next Image?
    if (mainImage.dataset.idx == this.galleryThumbItems.length - 1) {
      arrowNext.classList.add(
        "product_group_detail__gallery_main_arrow--hidden"
      );
    } else {
      arrowNext.classList.remove(
        "product_group_detail__gallery_main_arrow--hidden"
      );
    }
  }

  thumbHover(e) {
    const thumb = e.currentTarget;

    // Update gallery image
    this.updateGalleryMainImage(thumb.dataset.idx);
  }

  scrollToBeVisible(ele, container) {
    const eleTop = ele.offsetTop;
    const eleBottom = eleTop + ele.clientHeight;

    const containerTop = container.scrollTop;
    const containerBottom = containerTop + container.clientHeight;

    if (eleTop < containerTop) {
      // Scroll to the top of container
      container.scrollTop -= containerTop - eleTop;
    } else if (eleBottom > containerBottom) {
      // Scroll to the bottom of container
      container.scrollTop += eleBottom - containerBottom + 4;
    }
  }

  nextImage() {
    const mainImage = this.querySelector(
      ".product_group_detail__gallery_main_image"
    );

    const nextIdx = Math.min(
      parseInt(mainImage.dataset.idx) + 1,
      this.galleryThumbItems.length - 1
    );
    this.updateGalleryMainImage(nextIdx);

    this.scrollToBeVisible(
      this.galleryThumbItems[nextIdx],
      this.galleryThumbContainer
    );
  }

  prevImage() {
    const mainImage = this.querySelector(
      ".product_group_detail__gallery_main_image"
    );

    const prevIdx = Math.max(parseInt(mainImage.dataset.idx) - 1, 0);
    this.updateGalleryMainImage(prevIdx);

    this.scrollToBeVisible(
      this.galleryThumbItems[prevIdx],
      this.galleryThumbContainer
    );
  }

  updateGalleryMainImage(idx) {
    const mainImage = this.querySelector(
      ".product_group_detail__gallery_main_image"
    );
    const imageThumbnail = this.querySelector(
      `.product_group_detail__gallery_thumbs_item[data-idx="${idx}"]`
    );

    mainImage.src = imageThumbnail.dataset.urlZoom;
    mainImage.dataset.idx = idx;

    // Remove active class from all thumbs
    this.galleryThumbItems.forEach((thumb) => {
      thumb.classList.remove(
        "product_group_detail__gallery_thumbs_item--active"
      );
    });

    // Add active class to current thumb
    imageThumbnail.classList.add(
      "product_group_detail__gallery_thumbs_item--active"
    );

    // Check Arrow Visibility
    this.checkGalleryArrowVisibility();
  }

  async fetchRecommendations(maxRetries = 5) {
    try {
      const response = await fetch(
        `/ajax/recommendations/product_group/${this.dataset.pid}/`
      );
      const data = await response.text();

      if (response.status === 200) {
        this.recomContainer.insertAdjacentHTML("beforeend", data);
      } else {
        throw new Error(data);
      }
    } catch (error) {
      if (maxRetries > 0) {
        await this.fetchRecommendations(maxRetries - 1);
      } else {
        throw error;
      }
    }
  }
}
window.customElements.define(
  "product-group-detail",
  ProductGroupDetailComponent
);
