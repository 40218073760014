import { hoverIntent } from './hover-intent';


export class HeaderComponent extends HTMLElement {
  constructor() {
    super();
  }

  connectedCallback() {
    this.contentOverlay = document.querySelector("#content-overlay");

    // Sub-Navigation: mouseenter-/mouseleave-Event
    this.navItemContainerList = [];
    this.querySelectorAll(".header__nav-item-container").forEach((elt) => {
      if (elt.querySelector(".header__nav-item-child-container")) {
        this.navItemContainerList.push(elt);
      }
    });
    this.hoverIntent = new hoverIntent(this.navItemContainerList, {
          // required parameters
          onEnter: this.showNavItemChildContainer.bind(this),
          onExit: this.hideNavItemChildContainer.bind(this),
        
          // default options
          exitDelay: 0,
          interval: 100,
          sensitivity: 7,
        });
  }

  showNavItemChildContainer(elt) {
    if (
      this.contentOverlay &&
      !this.contentOverlay.classList.contains("page-overlay--visible")
    ) {
      this.contentOverlay.classList.add("page-overlay--visible");
    }

    elt
      .querySelectorAll(".header__nav-item-child-container")
      .forEach((elt_child) => {
        elt_child.classList.add("header__nav-item-child-container--active");
      });

    let relEltCenterPos = elt.offsetLeft + elt.offsetWidth / 2;
    elt.querySelector(
      ".header__nav-item-child-container-arrow"
    ).style.left = `${relEltCenterPos}px`;
  }

  hideNavItemChildContainer(elt) {
    if (
      this.contentOverlay &&
      this.contentOverlay.classList.contains("page-overlay--visible")
    ) {
      this.contentOverlay.classList.remove("page-overlay--visible");
    }

    elt
      .querySelectorAll(".header__nav-item-child-container")
      .forEach((elt_child) => {
        elt_child.classList.remove("header__nav-item-child-container--active");
      });
  }
}
window.customElements.define("header-component", HeaderComponent);
