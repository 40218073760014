import InfiniteScroll from "infinite-scroll";
import { getCookie } from "./utils";

export class ContentContainerComponent extends HTMLElement {
  constructor() {
    super();
  }

  connectedCallback() {
    // Init Infinite Scroll
    if (!!document.querySelector("#ajax_load_more")) {
      this.infiniteScroll = new InfiniteScroll(this, {
        path: "#ajax_load_more",
        append: false,
        checkLastPage: false,
        scrollThreshold: 1660,
        history: false,
      });
      this.infiniteScroll.on("load", (response, path) => {
        this.appendElementsToContainer(response);
      });
      window.requestAnimationFrame(() => {
        let loadMoreBtn = document.querySelector("#load_more_button");
        if (loadMoreBtn) {
          loadMoreBtn.remove();
        }
      });
    }

    // Init Beacon-Tracking
    this.csrfCookie = getCookie("csrftoken");
    this.beaconObserver = new IntersectionObserver(
      this.beaconCallback.bind(this),
      {
        rootMargin: "0px",
        threshold: 1.0,
      }
    );
    this.querySelectorAll("product-group").forEach((elt) => {
      this.beaconObserver.observe(elt);
      elt.dataset.ioObserved = true;
    });
  }

  appendElementsToContainer(response) {
    if (!response.querySelector("#ajax_load_more")) {
      if (!response.querySelector("#loading_products")) {
        this.appendProductsFragment(response);
      }
      if (!!response.querySelector("#product_recommendations_container")) {
        this.insertAdjacentElement(
          "afterend",
          response.querySelector("#product_recommendations_container")
        );
      }
      this.infiniteScroll.destroy();
    } else {
      this.appendProductsFragment(response);
    }
  }

  appendProductsFragment(response) {
    let fragment = document.createDocumentFragment();
    response.querySelectorAll(".product").forEach((elt) => {
      fragment.append(elt);
    });
    window.requestAnimationFrame(() => {
      this.appendChild(fragment);
      this.updateBeaconObservedElements();
    });
  }

  updateBeaconObservedElements() {
    this.querySelectorAll(".product:not([data-io-observed])").forEach((elt) => {
      this.beaconObserver.observe(elt);
    });
  }

  beaconCallback(entries, observer) {
    entries.forEach((entry) => {
      if (entry.isIntersecting && entry.intersectionRatio === 1) {
        const productGroup = entry.target;
        const beaconUrl = "/beacon/impression/";

        const formData = new FormData();
        formData.append("csrfmiddlewaretoken", this.csrfCookie);
        formData.append("product_group_id", productGroup.dataset.pid);

        navigator.sendBeacon(beaconUrl, formData);
        this.beaconObserver.unobserve(productGroup);
        productGroup.dataset.ioObserved = "true";
      }
    });
  }
}
window.customElements.define("content-container", ContentContainerComponent);
