import "../../../css/src/modules/hero-search-component.scss";


export class HeroSearchComponent extends HTMLElement {
    constructor() {
      super();
    }
  
    connectedCallback() {}
  }

  window.customElements.define("hero-search", HeroSearchComponent);